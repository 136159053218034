<template>
  <b-container fluid>
    <bo-page-title />
    <div class="dashboard">
      <b-row>
        <b-col md="9">
          <b-card>
            <h4>
              <strong>Howdy Administrator!</strong>
            </h4>
            <p>
              Welcome to the Lingkar Sembilan Website Administrator Page
              You are on the PROTECTED page of the Lingkar Sembilan Website Content Management system.
            </p>
            <ol>
              <li>Save and secure your Username and Password to avoid things that are not desirable.</li>
              <li>Prepare the material or content that will be updated first.</li>
              <li>Also prepare photos, videos or other related material, to facilitate the updating process.</li>
              <li>Use the latest version of the browser to get the compatibility features in this backoffice.</li>
            </ol>
            <!-- <p>
              Web Developer Contact:<br>
              Email: bara@lingkar9.com | hello@lingkar9.com <br>
              Phone: +62-8193-0962-159
            </p> -->
          </b-card>
        </b-col>
        <b-col md="3">
          <div class="card_dashboard bg_card_1 mb-3">
            <b-row>
              <b-col lg="4">
                <div class="wrap_ic_dsb">
                  <i class="ti-layout-list-thumb"></i>
                </div>
              </b-col>
              <b-col lg="8">
                <div class="wrap_total_sts">
                  <h3>{{dataCard.our_work}}</h3>
                  <p>Our Works</p>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="card_dashboard bg_card_2 mb-3">
            <b-row>
              <b-col lg="4">
                <div class="wrap_ic_dsb">
                  <i class="ti-layout-list-thumb"></i>
                </div>
              </b-col>
              <b-col lg="8">
                <div class="wrap_total_sts">
                  <h3>{{dataCard.journal}}</h3>
                  <p>Journal Published</p>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="card_dashboard bg_card_3">
            <b-row>
              <b-col lg="4">
                <div class="wrap_ic_dsb">
                  <i class="icon-briefcase"></i>
                </div>
              </b-col>
              <b-col lg="8">
                <div class="wrap_total_sts">
                  <h3>{{dataCard.vacancies}}</h3>
                  <p>Open Vacancies</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col lg="6">
          <b-card no-body :header-class="dataEvent.length ? 'card-dashboard-item' : 'card-dashboard-noitem'">
            <template #header>
              <div class="d-flex align-items-center justify-content-between">
                <h5 class="card-title">Inquiry</h5>
                <b-button class="btn-rounded" variant="info" 
                v-if="dataEvent.length"
                :to="{ name: 'BoInquiry'}"
                >
                  View All <i class="fa fa-arrow-circle-right ml-2"></i>
                </b-button>
              </div>
            </template>
						<b-table responsive :fields="inquiryFields" :items="dataTable.inquiry||[]" show-empty>
              <template #cell(num)="data">{{ data.index += 1 }}</template>
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(sender)="data">
                <div>{{ data.item.afi_name }}</div>

                <p class="mb-0 mt-3"><strong>Status</strong>: </p>
                <b-badge variant="success" v-if="data.item.afi_status == 'R'">Read</b-badge>
                <b-badge variant="warning" v-else>Unread</b-badge>
              </template>
              <template #cell(contact)="data">
                <span class="d-inline-block">
                  <i class="ti-email mr-1"></i>
                  <span>{{ data.item.afi_email }}</span>
                </span><br/>
                <span class="d-inline-block">
                  <i class="icon-phone mr-1"></i>
                  <span>{{ data.item.afi_phone_number }}</span>
                </span>
              </template>
              <template #cell(service)="data">
                <div>{{ data.item.afi_service }}</div>
              </template>
              <template #cell(date)="data">
                <div>{{ data.item.afi_created_date | moment('LLL') }}</div>
              </template>
              <template #empty>
                <div class="text-center">
                  <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
                  <h4 align="center"><span v-if="Object.keys(filter).length">no data shown</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
                </div>
              </template>
            </b-table>


          </b-card>
        </b-col>
        
        <b-col lg="6">
          <b-card no-body :header-class="dataNews.length ? 'card-dashboard-item' : 'card-dashboard-noitem'">
            <template #header>
              <div class="d-flex align-items-center justify-content-between">
                <h5 class="card-title">Data Hiring</h5>
                <b-button class="btn-rounded" variant="info"
                  :to="{ name: 'BoCareer' }"
                  v-if="dataNews.length"
                >
                  View All <i class="fa fa-arrow-circle-right ml-2"></i>
                </b-button>
              </div>
            </template>

            <b-table responsive :fields="hiringFields" :items="dataTable.hiring||[]" show-empty>
              <template #cell(num)="data">{{ data.index += 1 }}</template>
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(sender)="data">
                <div>{{ data.item.afca_name }}</div>

                <p class="mb-0 mt-3"><strong>Status</strong>: </p>
                <b-badge variant="success" v-if="data.item.afca_status == 'R'">Read</b-badge>
                <b-badge variant="warning" v-else>Unread</b-badge>
              </template>
              <template #cell(contact)="data">
                <span class="d-inline-block">
                  <i class="ti-email mr-1"></i>
                  <span>{{ data.item.afca_email }}</span>
                </span><br/>
                <span class="d-inline-block">
                  <i class="icon-phone mr-1"></i>
                  <span>{{ data.item.afca_phone_number }}</span>
                </span>
              </template>
              <template #cell(job_name)="data">
                <div>{{ data.item.afca_job_name }}</div>
              </template>
              <template #cell(date)="data">
                <div>{{ data.item.afca_created_date | moment('LLL') }}</div>
              </template>
              <template #empty>
                <div class="text-center">
                  <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
                  <h4 align="center"><span v-if="Object.keys(filter).length">no data shown</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
                </div>
              </template>
            </b-table>
						
          </b-card>
        </b-col>

        <b-col lg="12">          
          <b-card no-body :header-class="dataNews.length ? 'card-dashboard-item' : 'card-dashboard-noitem'">
            <template #header>
              <div class="d-flex align-items-center justify-content-between">
                <h5 class="card-title">Contact Inbound</h5>
                <b-button class="btn-rounded" variant="info"
                  :to="{ name: 'BoContact' }"
                  v-if="dataNews.length"
                >
                  View All <i class="fa fa-arrow-circle-right ml-2"></i>
                </b-button>
              </div>
            </template>
            <b-table responsive :fields="inboxFields" :items="dataTable.contact||[]" show-empty>
              <template #cell(num)="data">{{ data.index += 1 }}</template>
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(sender)="data">
                <div>{{ data.item.afc_name }}</div>
              </template>
              <template #cell(contact)="data">
                <span class="d-inline-block">
                  <i class="ti-email mr-1"></i>
                  <span>{{ data.item.afc_email }}</span>
                </span><br/>
                <span class="d-inline-block">
                  <i class="icon-phone mr-1"></i>
                  <span>{{ data.item.afc_phone_number }}</span>
                </span>
              </template>
              <template #cell(subject)="data">
                <div>{{ data.item.afc_subject }}</div>
              </template>
              <template #cell(date)="data">
                <div>{{ data.item.afc_created_date | moment('LLL') }}</div>
              </template>
              <template #cell(status)="data">
                <b-badge variant="success" v-if="data.item.afc_status == 'R'">Read</b-badge>
                <b-badge variant="warning" v-else>Unread</b-badge>
              </template>
              <template #empty>
                <div class="text-center">
                  <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
                  <h4 align="center"><span v-if="Object.keys(filter).length">no data shown</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
                </div>
              </template>
            </b-table>						
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>
<script>
import GlobalVue from '../../libs/Global.vue'

export default {
  extends: GlobalVue,
  data() {
    return {
			dataCard:{},
      dataTable: {},
			dataEvent:[],
			dataNews:[],	
			blogFields: [
				{ key: 'num', label: '#' },
				{ key: 'title'},
        { key: 'view', label: 'Total View'}
			],

      inboxFields: [{
					key: 'num',
					label: '#'
				}, 
				'sender', 
				'contact', 
				'subject', 
				'date',
				'status',
      ],

      inquiryFields: [{
					key: 'num',
					label: '#'
				}, 
				'sender', 
				'contact', 
				'service', 
				'date', ],

      hiringFields: [{
					key: 'num',
					label: '#'
				}, 
				'sender', 
				'contact', 
				'job_name', 
				'date',],
    }
  },
  mounted(){
    this.apiGet()
  },
  watch:{
    $route(){
      this.apiGet()
    }
  },
}
</script>